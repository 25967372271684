import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      token
      user {
        id
        email
      }
    }
  }
`;

export const REGISTER = gql`
  mutation register($tenantId: Int!, $input: RegisterInput!) {
    register(tenantId: $tenantId, input: $input) {
      token
    }
  }
`;
