import { useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { useModalToggle } from "../hooks";
import { Product } from "../types";
import ProductContent from "./item-content";

interface Props {
  items: Product[];
  eventId: number;
  refetch: () => void;
}

export default function ProductList({ items, refetch, eventId }: Props) {
  const { open, close, props } = useModalToggle();
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  function handleOpenModal(item: Product) {
    setSelectedProduct(item);
    open();
  }

  function handleClose() {
    refetch();
    setSelectedProduct(null);
    close();
  }

  return (
    <>
      <BottomSheet
        open={props.visible}
        blocking={false}
        snapPoints={({ maxHeight }) => 1 * maxHeight}
        onDismiss={close}
      >
        {selectedProduct && (
          <ProductContent
            eventId={eventId}
            close={handleClose}
            item={selectedProduct}
          />
        )}
      </BottomSheet>
      <div
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
      >
        {items
          .slice()
          .sort((a, b) => a.id - b.id)
          .map((item) => (
            <div
              onClick={() => handleOpenModal(item)}
              key={item.id}
              className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
            >
              <div className="flex flex-1 flex-col p-8">
                <img
                  className="mx-auto h-36 w-36 flex-shrink-0 rounded-full"
                  src={item.image}
                  alt={item.name}
                />
                <h3 className="mt-6 text-lg font-semibold text-gray-900">
                  {item.name}
                </h3>
                <dl className="mt-1 flex flex-grow flex-col justify-between">
                  <dt className="sr-only">Title</dt>
                  <dd className="text-sm text-gray-500">{item.description}</dd>
                  <dd className="mt-3">
                    {item.eventRating ? (
                      <div className="pr-4 flex items-center ml-3.5 mt-2">
                        <div className="mr-1 mt-2">
                          <p className="mt-1 font-medium">
                            {item.eventRating.toFixed(0)} out of 100
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="ml-4 text-gray-300">No Ratings Yet</div>
                    )}
                  </dd>
                </dl>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
