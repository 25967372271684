import logo from './img/icon.png';

export const useCloudinaryImage = (image?: string): string => {
  if (!image) {
    return logo;
  }
  return ``;
};

export function usePluralCount(singular: string, length?: number) {
  if (length === 0 || !length) {
    return `${length} ${singular}s`;
  }
  return length <= 1 ? `${length} ${singular}` : `${length} ${singular}s`;
}

export function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(' ');
}
