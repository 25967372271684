import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../img/logo.png";
import { REGISTER } from "../services/auth.service";
import { useEventById } from "../services/event.service";
import LoadingPage from "./loading";

export default function Register() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { event, loading: eventLoading } = useEventById(Number(id));
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const validateForm = () => {
    if (firstName === "") {
      alert("first name can not be empty!");
      return false;
    }
    if (lastName === "") {
      alert("last name can not be empty!");
      return false;
    }
    if (email === "") {
      alert("Email can not be empty!");
      return false;
    }
    if (password === "") {
      alert("Password can not be empty!");
      return false;
    }
    return true;
  };

  const [register] = useMutation(REGISTER, {
    onCompleted: ({ register }) => {
      localStorage.setItem("token", register.token);
      navigate(`/event/${id}`);
    },
    onError: (error) => {
      alert(error.message);
    },
  });

  async function handleLogin(event: React.FormEvent) {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    try {
      await register({
        variables: {
          tenantId: 3,
          input: {
            firstName,
            lastName,
            email,
            password,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  if (eventLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <div className="flex h-screen bg-gray-800">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-20 w-auto rounded-lg" src={logo} alt="usuite" />
              <h2 className="mt-6 text-3xl  font-extrabold text-gray-100">
                Check-in for {event.name} event
              </h2>
              <h2 className="mt-2 text-gray-100 marker:text-xl">
                {event.description}
              </h2>
            </div>
            <div className="mt-8">
              <a
                href={`/login/${id}`}
                className="text-center px-2 text-indigo-400"
              >
                Already have an account? Login here
              </a>
              <div>
                <div className="relative mt-6">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>

                  <div className="relative flex justify-center text-sm">
                    <span className="bg-gray-800 px-2 text-gray-100">
                      Or create your account below
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <form method="POST" className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-100"
                    >
                      First Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="firstName"
                        name="firstName"
                        type="firstName"
                        autoComplete="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-100"
                    >
                      Last Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="lastName"
                        name="lastName"
                        type="lastName"
                        autoComplete="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-100"
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-100"
                    >
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      onClick={handleLogin}
                      disabled={loading}
                      className="flex w-full justify-center rounded-md border border-transparent bg-gradient-to-r from-teal-500 to-indigo-500 py-3 px-4 text-sm font-medium text-white shadow-sm"
                    >
                      {loading ? "Loading..." : "Sign up & Check-in"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={event.image}
            alt="gym"
          />
        </div>
      </div>
    </>
  );
}
