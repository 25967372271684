import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from "react-router-dom";
import EventById from "./pages/event-by-id";
import Login from "./pages/login";
import Register from "./pages/register";
import GraphQLProvider from "./providers/graphql-provider";
import Footer from "./components/footer";

export default function App() {
  const isMobileDevice = (): boolean => {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  };

  if (!isMobileDevice()) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h1>Sorry, this app is only available on mobile devices</h1>
        <Footer />
      </div>
    );
  }

  return (
    <GraphQLProvider>
      <Router>
        <Switch>
          <Route path="/login/:id" element={<Login />} />
          <Route path="/register/:id" element={<Register />} />
          <Route path="/event/:id" element={<EventById />} />
        </Switch>
      </Router>
    </GraphQLProvider>
  );
}
