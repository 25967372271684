import { gql, useQuery } from "@apollo/client";
import { User } from "../types";

export const ME = gql`
  query me {
    me {
      id
      firstName
      lastName
      email
    }
  }
`;

export function useMe() {
  const { data, loading, refetch, error } = useQuery(ME);
  const me: User = data?.me;
  return { me, loading, refetch, error };
}
