import { gql, useQuery } from "@apollo/client";
import { Event, Rating } from "../types";
export const CREATE_EVENT = gql`
  mutation createEvent($input: EventInput!) {
    createEvent(input: $input) {
      id
      name
    }
  }
`;

export const EVENTS = gql`
  query events {
    events {
      name
      image
      description
      start
      end
    }
  }
`;

export function useEvents() {
  const { data, loading, refetch, error } = useQuery(EVENTS);
  const events: Event[] = data?.events;
  return { events, loading, refetch, error };
}

export const EVENT_BY_ID = gql`
  query event($id: Int!) {
    event(id: $id) {
      id
      name
      description
      start
      end
      products {
        id
        name
        image
        description
        eventRating(id: $id)
        ratings(id: $id) {
          id
          createdAt
          comment
          user {
            firstName
            lastName
            email
          }
          rating
        }
      }
    }
  }
`;

export function useEventById(id?: number) {
  const { data, loading, refetch, error } = useQuery(EVENT_BY_ID, {
    variables: { id },
    skip: id === undefined,
    pollInterval: 5000,
  });
  const event: Event = data?.event;
  return { event, loading, refetch, error };
}

export const MY_EVENT_ITEM_RATING = gql`
  query event($eventId: Int!, $productId: Int!) {
    myEventProductRating(eventId: $eventId, productId: $productId) {
      rating
      comment
    }
  }
`;

export function useMyEventProductRating(eventId: number, productId: number) {
  const { data, loading, refetch, error } = useQuery(MY_EVENT_ITEM_RATING, {
    variables: { eventId, productId },
  });
  const rating: Rating = data?.myEventProductRating;
  return { rating, loading, refetch, error };
}
