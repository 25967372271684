export default function Footer() {
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 flex justify-center md:flex md:items-center md:justify-between lg:px-8">
        <div className="mt-8 md:mt-0 md:order-1">
          <div className="text-center text-base text-indigo-400 cursor-pointer">
            <a
              href="https://www.daylonball.com"
              target={"__blank"}
              className="hover:text-indigo-500 text-sm"
            >
              &copy;
              {`${new Date().getFullYear()} `}
              <strong
                className="text-indigo-500"
                style={{ textDecoration: "underline" }}
              >
                DB Technical Consulting
              </strong>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
