import { useState } from 'react';

export function useModalToggle() {
  const [visible, setVisible] = useState(false);
  function onCancel() {
    setVisible(false);
  }
  function open() {
    setVisible(true);
  }
  function close() {
    setVisible(false);
  }
  return {
    props: {
      visible,
      onCancel,
    },
    open,
    close,
  };
}
