import { useMutation } from "@apollo/client";
import { Tab } from "@headlessui/react";
import moment from "moment";
import { Fragment, useState } from "react";
import { classNames } from "../helpers";
import LoadingPage from "../pages/loading";
import {
  EVENT_BY_ID,
  useMyEventProductRating,
} from "../services/event.service";
import { RATE_ITEM } from "../services/items.service";
import { Product } from "../types";

interface Props {
  item: Product;
  eventId: number;
  close: () => void;
}

export default function ProductContent({ item, close, eventId }: Props) {
  const { rating: myRating, loading } = useMyEventProductRating(
    eventId,
    item.id
  );
  const [rateProduct] = useMutation(RATE_ITEM, {
    refetchQueries: [EVENT_BY_ID],
  });
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  async function submitRating() {
    if (rating > 100) {
      alert("Rating cannot be greater than 100");
      return;
    }
    try {
      await rateProduct({
        variables: {
          rating: {
            productId: item.id,
            eventId,
            rating,
            comment,
          },
        },
      });
      alert("Rating Submitted!");
      close();
    } catch (e) {
      alert("Error");
    }
  }

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="bg-white pb-12">
      <div className="mx-auto pt-4 px-4 sm:pt-12 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-rows-1 lg:grid-cols-7 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
          <div className="lg:row-end-1 lg:col-span-4">
            <div className="aspect-w-1 aspect-h-1 rounded-lg  overflow-hidden">
              <img
                src={item.image}
                alt={item.name}
                className="object-center object-cover h-60"
              />
            </div>
          </div>

          <div className="max-w-2xl mx-auto mt-14 sm:mt-16 lg:max-w-none lg:mt-0 lg:row-end-2 lg:row-span-2 lg:col-span-3">
            <div className="flex flex-col-reverse">
              <div className="mt-4">
                <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                  {item.name}
                </h1>
              </div>
              <div>
                <div className="flex items-center"></div>
                <p className="mt-1 font-medium">
                  {item.eventRating.toFixed(0)} out of 100
                </p>
              </div>
            </div>

            <p className="text-gray-500 mt-6">{item.description}</p>
          </div>

          <div className="flex flex-col mt-12 border-t">
            <p className="text-gray-800 my-2 text-lg font-medium">
              Your Rating:
            </p>
            {myRating ? (
              `${myRating.rating.toFixed(0)} out of 100`
            ) : (
              <div className="mt-1 relative rounded-md shadow-lg">
                <input
                  name="price"
                  id="price"
                  max={100}
                  maxLength={3}
                  min={0}
                  type="number"
                  onChange={(e) => setRating(Number(e.target.value))}
                  className="focus:ring-indigo-500 py-3 text-lg focus:border-indigo-500 block w-full pl-7 pr-12 border-gray-300 rounded-md"
                  aria-describedby="price-currency"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span
                    className="text-gray-500 sm:text-sm"
                    id="price-currency"
                  >
                    / 100
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="mt-4">
            <label
              htmlFor="comment"
              className="block text-lg font-medium text-gray-700"
            >
              {myRating ? "Your Review/Comment:" : "Add your tasting comment:"}
            </label>
            <div className="mt-1">
              <textarea
                rows={4}
                name="comment"
                id="comment"
                value={myRating ? myRating.comment : comment}
                disabled={!!myRating}
                onChange={({ target: { value } }) => setComment(value)}
                className="shadow-lg border p-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                defaultValue={""}
              />
            </div>
          </div>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
            {!myRating && (
              <button
                type="button"
                onClick={submitRating}
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-teal-500 to-indigo-400 py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
              >
                Submit Rating
              </button>
            )}
            <button
              onClick={close}
              type="button"
              className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-50 py-3 px-8 text-base font-medium text-indigo-700 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
            >
              Close
            </button>
          </div>

          <div className="w-full max-w-2xl mx-auto mt-4 lg:max-w-none lg:mt-0 lg:col-span-4">
            <Tab.Group as="div">
              <div className="border-b border-gray-200">
                <Tab.List className="-mb-px flex space-x-8">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? "border-indigo-600 text-indigo-600"
                          : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                        "whitespace-nowrap py-6 border-b-2 font-medium text-sm"
                      )
                    }
                  >
                    Reviews
                  </Tab>
                </Tab.List>
              </div>
              <Tab.Panels as={Fragment}>
                <Tab.Panel className="-mb-10">
                  <h3 className="sr-only">Customer Reviews</h3>

                  {item.ratings.length === 0 ? (
                    <div className="mt-4">No Reviews Yet</div>
                  ) : (
                    item.ratings
                      .slice()
                      .sort(({ createdAt: a }, { createdAt: b }) => b - a)
                      .map(
                        ({ user, rating, createdAt, comment }, reviewIdx) => (
                          <div
                            key={reviewIdx}
                            className="flex text-sm text-gray-500 space-x-4"
                          >
                            <div className="flex-none py-10">
                              <span className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gradient-to-r from-red-400 to-orange-400">
                                <span className="text-lg font-medium leading-none text-white">
                                  {user.firstName.charAt(0)}
                                  {user.lastName.charAt(0)}
                                </span>
                              </span>
                            </div>
                            <div
                              className={classNames(
                                reviewIdx === 0
                                  ? undefined
                                  : "border-t border-gray-200",
                                "py-10"
                              )}
                            >
                              <h3 className="font-medium text-gray-900">
                                {user.firstName} {user.lastName}
                              </h3>
                              <p>
                                {moment(createdAt).format("h:mm a : MMM Do")}
                              </p>
                              <p>{rating.toFixed(0)} out of 100</p>
                              <div className="mt-4 prose prose-sm max-w-none text-gray-500">
                                {comment === "" ? "N/A" : comment}
                              </div>
                            </div>
                          </div>
                        )
                      )
                  )}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    </div>
  );
}
