import { gql, useQuery } from "@apollo/client";
import { Product } from "../types";
export const CREATE_ITEM = gql`
  mutation createProduct($input: ProductInput!) {
    createProduct(input: $input) {
      id
      name
    }
  }
`;

export const ITEMS = gql`
  query items {
    items {
      name
      description
      image
    }
  }
`;

export function useProducts() {
  const { data, loading, refetch, error } = useQuery(ITEMS);
  const items: Product[] = data?.items;
  return { items, loading, refetch, error };
}

export const RATE_ITEM = gql`
  mutation createRating($rating: ProductRatingInput!) {
    createRating(rating: $rating) {
      rating
    }
  }
`;
