import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, UserIcon, XIcon } from "@heroicons/react/outline";
import { Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../components/footer";
import { classNames } from "../helpers";
import icon from "../img/logo.png";
import ProductList from "../items/item-list";
import { useEventById } from "../services/event.service";
import { useMe } from "../services/user.service";
import LoadingPage from "./loading";

const userNavigation = [{ name: "Sign out" }];

export default function EventById() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { me, loading: userLoading } = useMe();
  const { event, loading, error, refetch } = useEventById(Number(id));
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate(`/login/${id}`);
    }
  }, [token, navigate, id]);

  if (loading || userLoading) return <LoadingPage />;
  if (!event || error) return <>Error</>;

  function handleLogout() {
    localStorage.getItem("token");
    navigate(`/login/${id}`);
  }

  return (
    <>
      <div className="min-h-full">
        <div className="bg-gray-800 pb-32">
          <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
              <>
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                  <div className="border-b border-gray-700">
                    <div className="flex items-center justify-between h-16 px-4 sm:px-0">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 p-1 rounded-lg">
                          <img className="h-8 w-full" src={icon} alt="logo" />
                        </div>
                      </div>
                      <div className="hidden md:block">
                        <div className="ml-4 flex items-center md:ml-6">
                          <Menu as="div" className="ml-3 relative">
                            <div>
                              <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                <span className="sr-only">Open user menu</span>
                                <img
                                  className="h-8 w-full rounded-full"
                                  src={icon}
                                  alt="logo"
                                />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Item>
                                <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  {userNavigation.map((item) => (
                                    <Menu.Item key={item.name}>
                                      {({ active }) => (
                                        <button
                                          onClick={handleLogout}
                                          className={classNames(
                                            active ? "bg-gray-100" : "",
                                            "block px-4 py-2 text-sm text-gray-700"
                                          )}
                                        >
                                          {item.name}
                                        </button>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </div>
                              </Menu.Item>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                      <div className="-mr-2 flex md:hidden">
                        <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <MenuIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      </div>
                    </div>
                  </div>
                </div>
                <Disclosure.Panel className="border-b border-gray-700 md:hidden">
                  <div className="pt-4 pb-3 border-t border-gray-700">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0 rounded-md border-gray-400 border-2 p-0.5">
                        <UserIcon className="h-8 w-8 text-white rounded-full" />
                      </div>
                      <div className="ml-3">
                        <div className="text-lg font-medium leading-none text-white">
                          {me?.firstName} {me?.lastName}
                        </div>
                        <div className="mt-1.5 text-sm font-medium leading-none text-gray-400">
                          {me?.email}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 px-2 space-y-1">
                      {userNavigation.map((item) => (
                        <button
                          key={item.name}
                          onClick={handleLogout}
                          className="block px-3 py-2 rounded-md text-base font-semibold text-gray-200 hover:text-white hover:bg-gray-700"
                        >
                          {item.name}
                        </button>
                      ))}
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <header className="py-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold text-white">{event.name}</h1>
              <h1 className="text-1xl mt-1 font-semibold text-gray-200">
                {event.description}
              </h1>
            </div>
          </header>
        </div>
        <main className="-mt-32">
          <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
            <ProductList
              refetch={refetch}
              eventId={Number(id)!}
              items={event.products}
            />
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
}
