import Lottie from "lottie-react";
import React from "react";
import loadingAnimation from "../img/loading.json";
import logo from "../img/logo.png";

export default function LoadingPage() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-black py-12 sm:px-6 lg:px-8">
      <img src={logo} className="h-14" alt="cubbi" />
      <div className="mb-18 sm:mx-auto sm:w-full sm:max-w-md">
        <Lottie animationData={loadingAnimation} style={{ height: 175 }} loop />
      </div>
    </div>
  );
}
